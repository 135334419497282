import Api from "@/services/Index";

const create = async (data, job) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if(job != 1){
        data.responseType = 'arraybuffer'
    }

    return Api.get('/student/programs/contact-information-excel-export', data);
}
export default {
   create
}
